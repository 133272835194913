import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import ServiceImage1 from '../img/framing.jpg';
import ServiceImage2 from '../img/drywall.jpg';
import ServiceImage3 from '../img/mudding1.jpeg';
import ServiceImage4 from '../img/taping.jpeg';
import ServiceImage5 from '../img/texture.jpeg';
import ServiceImage6 from '../img//insulate.jpeg';

function Services() {
    useEffect(() => {
        new window.Swiper('.testimonials-slider', {
           slidesPerView: 1,
           spaceBetween: 30,
           pagination: {
             el: '.swiper-pagination',
             type: 'bullets',
             dynamicBullets: true,
             clickable: true,
           },
           breakpoints: {
             991: {
               slidesPerView: 2,
             },
           },
         });
       }, []);
  return (
    <section class="py-5 bg-light swiper testimonials-slider" >
      <div class="container py-5">
        <header class="text-center mb-5">
          <div class="row">
            <div class="col-lg-7 mx-auto">
              <h2 class="text-uppercase"><span class="text-primary">Our </span>Services</h2>
              <p class="text-muted">RA Interiors provides expert services in framing, drywalling, mudding, taping, and ceiling texture for both commercial and residential projects in Texas, USA. Known for our meticulous craftsmanship and precision, we offer comprehensive solutions for construction and renovation, delivering exceptional quality in every aspect of our work.</p>
            </div>
          </div>
        </header>
        <div class="swiper-wrapper row text-center gy-4">
          <div class="swiper-slide col-lg-4">
            <div class="shadow-sm box">
              <div class="with-border-image-sm">
                <div class="position-relative overflow-hidden d-block">
                  <div class="box-overlay"></div>
                  <img className="w-100 h-100" src={ServiceImage1} alt=""/>
                </div>
              </div>
              <div class="p-4 border bg-white">
                <h3 class="h5"> <a class="reset-anchor" href="#!">Framing</a></h3>
                <p class="text-sm text-muted">Essential for structural support in construction, combining wood or metal as per regulations.</p>
                <Link class="btn btn-primary btn-sm text-white" to="/pages/services/framing">Read more</Link>
              </div>
            </div>
          </div>
          <div class="swiper-slide col-lg-4">
            <div class="shadow-sm box">
              <div class="with-border-image-sm">
                <div class="position-relative overflow-hidden d-block">
                  <div class="box-overlay"><a class="d-block w-100 h-100" href="#!"></a></div>
                  <img className="w-100 h-100" src={ServiceImage2} alt=""/>
                </div>
              </div>
              <div class="p-4 border bg-white">
                <h3 class="h5"> <a class="reset-anchor" href="#!">Drywalling</a></h3>
                <p class="text-sm text-muted">Transforming spaces with meticulous wall installations and finishes.</p>
                <Link class="btn btn-primary btn-sm text-white" to="/pages/services/drywall">Read more</Link>
              </div>
            </div>
          </div>
          <div class="swiper-slide col-lg-4">
            <div class="shadow-sm box">
              <div class="with-border-image-sm">
                <div class="position-relative overflow-hidden d-block">
                  <div class="box-overlay"><a class="d-block w-100 h-100" href="#!"></a></div>
                  <img className="w-100" src={ServiceImage3} alt=""/>
                </div>
              </div>
              <div class="p-4 border bg-white">
                <h3 class="h5"> <a class="reset-anchor" href="#!">Mudding</a></h3>
                <p class="text-sm text-muted">Achieving seamless surfaces through expert application and smoothing.</p>
                <Link class="btn btn-primary btn-sm text-white" to="/pages/services/mudding">Read more</Link>
              </div>
            </div>
          </div>
          <div class="swiper-slide col-lg-4">
            <div class="shadow-sm box">
              <div class="with-border-image-sm">
                <div class="position-relative overflow-hidden d-block">
                  <div class="box-overlay"><a class="d-block w-100 h-100" href="#!"></a></div>
                  <img className="w-100" src={ServiceImage4} alt=""/>
                </div>
              </div>
              <div class="p-4 border bg-white">
                <h3 class="h5"> <a class="reset-anchor" href="#!">Tapping</a></h3>
                <p class="text-sm text-muted">Ensuring invisible joints with precise tape application and blending.</p>
                <Link class="btn btn-primary btn-sm text-white" to="/pages/services/tapping">Read more</Link>
              </div>
            </div>
          </div>
          <div class="swiper-slide col-lg-4">
            <div class="shadow-sm box">
              <div class="with-border-image-sm">
                <div class="position-relative overflow-hidden d-block">
                  <div class="box-overlay"><a class="d-block w-100 h-100" href="#!"></a></div>
                  <img className="w-100" src={ServiceImage5} alt=""/>
                </div>
              </div>
              <div class="p-4 border bg-white">
                <h3 class="h5"> <a class="reset-anchor" href="#!">Ceiling Texture</a></h3>
                <p class="text-sm text-muted">Adding character and dimension with custom ceiling textures.</p>
                <Link class="btn btn-primary btn-sm text-white" to="/pages/services/ceiling">Read more</Link>
              </div>
            </div>
          </div>
          <div class="swiper-slide col-lg-4">
            <div class="shadow-sm box">
              <div class="with-border-image-sm">
                <div class="position-relative overflow-hidden d-block">
                  <div class="box-overlay"><a class="d-block w-100 h-100" href="#!"></a></div>
                  <img className="w-100" src={ServiceImage6} alt=""/>
                </div>
              </div>
              <div class="p-4 border bg-white">
                <h3 class="h5"> <a class="reset-anchor" href="#!">Insulating</a></h3>
                <p class="text-sm text-muted">Enhancing comfort and energy efficiency with effective insulation solutions.</p>
                <Link class="btn btn-primary btn-sm text-white" to="/pages/services/insulating">Read more</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
