import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import { HashRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ScrollToTop from './components/scroll-to-top';
import AboutUs from './pages/about'
import Contact from './pages/contact'
import Service from './pages/services/index'
import Drywall from './pages/services/drywall'
import Mudding from './pages/services/mudding'
import Tapping from './pages/services/tapping'
import Ceiling from './pages/services/ceiling'
import Framing from './pages/services/framing'
import Insulating from './pages/services/insulating'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
    <ScrollToTop/>
      <Routes>
        <Route path="/" element={ <App /> }>
        </Route>
        <Route path="/pages/about" element={ <AboutUs />}>
        </Route>
        <Route path="/pages/contact" element={ <Contact />}>
        </Route>
        <Route path="/pages/services" element={ <Service />}>
        </Route>
        <Route path="/pages/services/drywall" element={ <Drywall />}>
        </Route>
        <Route path="/pages/services/mudding" element={ <Mudding />}>
        </Route>
        <Route path="/pages/services/tapping" element={ <Tapping />}>
        </Route>
        <Route path="/pages/services/ceiling" element={ <Ceiling />}>
        </Route>
        <Route path="/pages/services/framing" element={ <Framing />}>
        </Route>
        <Route path="/pages/services/insulating" element={ <Insulating />}>
        </Route>
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
