import React from 'react';
import { Link } from 'react-router-dom'

function QuoteDivider() {
  return (
    <section class="bg-cover bg-center"  >
      <div class="dark-overlay">
        <div class="py-5 text-white overlay-content">
          <div class="container">
            <div class="row align-items-center gy-4">
              <div class="col-lg-7 text-center text-lg-start">
                <h2 class="h3 mb-3">Trusted <span class="text-primary">Since 2010</span></h2>
                <p class="text-sm mb-0">Need a quote for your next construction or renovation project? Reach out to RA Interiors for a prompt and detailed estimate.</p>
              </div>
              <div class="col-lg-5 text-center text-lg-end">
                <Link class="btn btn-outline-light text-white" to="/pages/contact">Get a quote</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}

export default QuoteDivider;
