import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import PageHeader from '../components/page-header';
import ServiceImage from '../img/about.jpeg';

const AboutUs = () => {
    return (
        <div>
            <Header/>
            <PageHeader title="About" />
            <section class="py-5 bg-light">
                <div class="container py-5">
                    <header class="text-center mb-5">
                    <div class="row">
                        <div class="col-lg-7 mx-auto">
                        <h2 class="text-uppercase">About <span class="text-primary"> RA Interiors</span></h2>
                        </div>
                    </div>
                    </header>
                    <div class="col-lg-7 mx-auto">
                                <p>Since our inception in 2010, RA Interiors has been at the forefront of providing top-notch services in framing, drywalling, mudding, taping, and ceiling texture for both commercial and residential projects throughout North America. Our commitment to meticulous craftsmanship, precision, and delivering comprehensive solutions has set us apart in the construction and renovation industry.</p>
                                <br/>     <br/>
                                <img className="w-100" src={ServiceImage} alt=""/>
                                <br/>     <br/>     <br/>
                                <h2>Why Choose RA Interiors?</h2>
                                <ul class="reasons-list">
                                    <li><strong>Expertise in Framing:</strong> Whether it's wood or steel stud framing, our team provides affordable, professional, and high-quality services.</li>
                                    <li><strong>Advanced Insulation Solutions:</strong> We understand the importance of efficient insulation for energy savings and noise reduction.</li>
                                    <li><strong>Professional Drywall Installation:</strong> Our skilled professionals manage every phase of drywall installation with ease and efficiency.</li>
                                    <li><strong>Exceptional Drywall Finishing:</strong> We take pride in our drywall finishing skills, ensuring a flawless finish.</li>
                                    <li><strong>Premium Level 5 Finish:</strong> For unparalleled quality, our Level 5 finish provides a perfectly smooth surface.</li>
                                    <li><strong>Customer-Centric Approach:</strong> We prioritize our clients' needs and work collaboratively to bring their vision to life.</li>
                                    <li><strong>Wide Range of Services:</strong> Our expertise isn't limited to just framing and drywalling. We offer comprehensive solutions including ceiling texture.</li>
                                    <li><strong>A Decade of Excellence:</strong> With over a decade in the industry, our experience speaks for itself.</li>
                                    <li><strong>Versatility:</strong> We cater to a diverse range of projects, both commercial and residential.</li>
                                    <li><strong>Committed to Quality:</strong> Our relentless pursuit of perfection in every task guarantees the best results.</li>
                                </ul>

                                <p>Join the multitude of satisfied customers who have experienced the RA Interiors difference. For your next project, trust us to deliver excellence and sophistication. Contact us today and let us help you turn your construction and renovation dreams into reality!</p>
                 </div>
                </div>
           </section>
           <Footer/>
        </div>
    );
}

export default AboutUs;
