
import './App.css';
import Header from './components/header';
import Footer from './components/footer.js';
import Testimonial from './components/testimonial';
import Services from './components/service';
import QuoteDivider from './components/quote-divider';

function App() {
  return (
    <div className="App">
      <Header activeLink="Home"/>
      <Services/>
      <QuoteDivider/>
      <Testimonial/>
      <Footer/>
    </div>
  );
}

export default App;
