import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import ServiceImage from '../../img/texture.jpeg';

const Ceiling = () => {
    return (
        <div>
            <Header/>
            <section className='py-5 bg-light'>
                    <div class="container mt-5 mb-5">
                        <div class="row justify-content-between">

                            <div class="col-md-5">
                                <img className="w-100" src={ServiceImage} alt=""/>
                            </div>
                            
                            <div class="col-md-6">
                                <h2>Master Craftsmen in Textured Ceilings</h2>
                                <p>At RA Interiors, we specialize in transforming your ceilings into works of art. Our expertise lies in a variety of textured ceiling services, including the classic popcorn, elegant knockdown, and unique splattered coat finishes.</p>
                                
                                <h4>Our Services Include:</h4>
                                <p><strong>Popcorn Ceilings:</strong> Bring a traditional and cozy feel to your interiors with our popcorn ceiling solutions. Perfect for noise reduction and adding character to any room.</p>
                                <p><strong>Knockdown Textures:</strong> For those who prefer a more subtle and modern look, our knockdown textures offer a smooth, refined finish that adds depth and sophistication to your spaces.</p>
                                <p><strong>Splattered Coat Textures:</strong> Choose our splattered coat textures for a distinctive and artistic touch. This technique creates a unique, patterned appearance that can elevate the design of any room.</p>
                            </div>
                        </div>
                    </div>
            </section>
           <Footer/>
        </div>
    );
}

export default Ceiling;
