import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import ServiceImage from '../../img/drywall.jpg';

const Drywalling = () => {
    return (        
    <div>
        <Header/>
        <section className='py-5 bg-light'>
                <div class="container mt-5 mb-5">
                    <div class="row justify-content-between">

                        <div class="col-md-4">
                            <img className="w-100" src={ServiceImage} alt=""/>
                        </div>
                        
                        <div class="col-md-7">
                            <h2>Residential and Commercial Drywall Installation Services</h2>
                               <div class="mt-4">
                                    <p>In need of drywall installation? Don't let improperly installed drywall compromise the comfort and safety of your home. Our team of experts is here to ensure a top-quality job, completed swiftly and efficiently. We offer a comprehensive range of services including installation, taping, mudding, floating, priming, texturing, and painting. Beyond drywall, we also specialize in construction, home repairs, and renovations, encompassing carpentry for drywall framing and more.
                                    </p>
                                    <p>Drywall installation is a challenging task, even for seasoned DIY enthusiasts. For substantial renovations involving drywall, it's advisable to engage a professional company. The benefits of hiring a drywall contractor are significant. These professionals bring years of experience in precise measurements, accurate cuts, and efficient use of materials. Their expertise extends to applying the right amount of mud to seams, determining appropriate drying times, and excelling in sanding for a smooth, seamless finish.
                                    </p>
                                </div>

                                <div class="mt-4">
                                    <h5>Consider the Time and Stress Saved</h5>
                                    <p>Imagine the time involved in measuring, cutting, and installing drywall throughout your home. This process also involves purchasing materials and tools, transportation, and learning various techniques like taping, coating, installing corner beads, texturing ceilings, and sanding. A single mistake could mean repeated trips to the store for more supplies.</p>
                                    <p>By hiring a professional drywall contractor, you save not only on the cost of tools and supplies you might never use again but also the time and hassle of multiple store visits. Contractors handle all aspects of the job, including cleanup and disposal, further saving your time.</p>
                                </div>

                                <div class="mt-4">
                                    <h5>Our Promise to You</h5>
                                    <ul>
                                        <li><strong>Efficiency:</strong> We complete your project promptly without compromising quality.</li>
                                        <li><strong>Professionalism:</strong> Our trained, experienced team is dedicated to providing the best service.</li>
                                        <li><strong>Customer Satisfaction:</strong> We listen to your needs and tailor our services to meet them.</li>
                                    </ul>
                                </div>

                                <div class="mt-4">
                                    <h5>Your Partner in Construction and Renovation</h5>
                                    <p>Beyond drywall, RA Interiors offers a range of services for construction, home repairs, and renovation. Trust us to handle your project with skill and dedication.</p>
                                </div>
                        </div>
                    </div>
                </div>
        </section>
       <Footer/>
    </div>
            
    );
}

export default Drywalling;
