import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import ServiceImage from '../../img/insulate.jpeg';

const Insulating = () => {
    return (
        <div>
        <div>
            <Header/>
            <section className='py-5 bg-light'>
                    <div class="container mt-5 mb-5">
                        <div class="row justify-content-between">

                            <div class="col-md-5">
                                <img className="w-100" src={ServiceImage} alt=""/>
                            </div>
                            
                            <div class="col-md-6">
                                <h2>Comprehensive Insulation Solutions</h2>
                                <p>At RA Interiors Ltd, our insulation team brings extensive expertise to enhance the comfort of your home. Insulation plays a critical role in reducing your heating and cooling expenses, while also lessening exterior noise and vibrations, making it a vital component in both new constructions and remodels. We commit to using only the highest-quality materials to ensure the insulation we install perfectly complements your specific renovation or building project.</p>
                                
                                <p>Offering both residential and commercial insulation solutions, RA Interiors Ltd specializes in insulating walls, garages, and basements. Our services empower you to convert underutilized areas into welcoming spaces for your family to enjoy all year long.</p>

                            </div>
                        </div>
                    </div>
            </section>
           <Footer/>
        </div>
        </div>
    );
}

export default Insulating;
