import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import ServiceImage from '../../img/taping.jpeg';

const Tapping = () => {
    return (
        <div>
        <div>
            <Header/>
            <section className='py-5 bg-light'>
                    <div class="container mt-5 mb-5">
                        <div class="row justify-content-between">

                            <div class="col-md-5">
                                <img className="w-100" src={ServiceImage} alt=""/>
                            </div>
                            
                            <div class="col-md-6">

                                <h2>Precision Taping: Elevating Drywall Artistry with RA Interiors</h2>
                                <p>Finishing drywall, known as taping, involves the precise application of paper or fiberglass tape over the joints between installed drywall panels. This critical step demands professional skill and meticulous attention to detail to ensure the walls are seamlessly smooth. At RA Interiors, we excel in delivering professional taping services that guarantee a flawless finish, with no protrusions or imperfections. Our expertise in taping is unmatched, making us the best in the business.
                                   Serving both the USA and Canada,, RA Interiors is your go-to source for superior drywall finishing. Contact us today to learn more about our services. We are always eager to undertake new projects, offering the highest quality drywall construction to enhance your space.</p>
                                
                                <p>Serving both the USA and Canada, including the Greater Toronto Area (GTA), RA Interiors is your go-to source for superior drywall finishing. Contact us today to learn more about our services. We are always eager to undertake new projects, offering the highest quality drywall construction to enhance your space.</p>

                            </div>
                        </div>
                    </div>
            </section>
           <Footer/>
        </div>
        </div>
    );
}

export default Tapping;
