import React from 'react';
import ServiceImage2 from '../img/footer.jpg'; 
import LogoWhite from '../img/logo2.jpg'; 

function Footer() {
    const footerNavItems = [
        { path: "/", label: "Home" },
        { path: "/pages/about", label: "About" },
        { path: "/pages/services", label: "Services" },
        { path: "/pages/contact", label: "Contact" }
      ]

  return (
    <footer className="text-white bg-cover bg-center" style={{ background: `url(${ServiceImage2})` }}>
      <div className="dark-overlay">
        <div className="overlay-content">
          <div className="container py-5">
            <div className="row py-5">
              {/* Logo and contact info */}
              <div className="col-lg-4 col-md-6">
                <img className="mb-4" src={LogoWhite} alt="" width="120"/>
                <p className="text-light text-sm mb-4"></p>
                <p className="mb-1"><i className="fas me-3 text-primary fa-fw fa-envelope"></i><span className="text-sm">info@rainteriors.us</span></p>
                <p className="mb-1"><i className="fas me-3 text-primary fa-fw fa-mobile"></i><span className="text-sm">+1 403 903 9463</span></p>
                <p className="mb-1"><i className="fas me-3 text-primary fa-fw fa-map-marker-alt"></i><span className="text-sm">1798 Pretty View Lane, California</span></p>
              </div>
              <div class="col-lg-2 col-md-6">
                <h6 class="text-uppercase text-primary mt-3 mb-4">Quick <span class="text-white">links</span></h6>
                <ul class="list-unstyled">
                  {footerNavItems.map(item => (
                    <li key={item.label} className="mb-2">
                    <a 
                        className={`footer-link`} 
                        href={item.path}
                    >
                        {item.label}
                    </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div class="col-lg-2 col-md-6">
                <h6 class="text-uppercase text-primary mt-3 mb-4">Our <span class="text-white">services</span></h6>
                <ul class="list-unstyled">
                  <li class="mb-2"><a class="footer-link" href="/pages/services/framing">Framing</a></li>
                  <li class="mb-2"><a class="footer-link" href="/pages/services/drywall">Drywalling</a></li>
                  <li class="mb-2"><a class="footer-link" href="/pages/services/mudding">Mudding</a></li>
                  <li class="mb-2"><a class="footer-link" href="/pages/services/tapping">Tapping</a></li>
                  <li class="mb-2"><a class="footer-link" href="/pages/services/ceiling">ceiling Texture</a></li>
                  <li class="mb-2"><a class="footer-link" href="/pages/services/insulating">Insulating</a></li>
                </ul>
              </div>
              <div class="col-lg-4 col-md-6">
                <h6 class="text-uppercase text-primary mt-3 mb-4">Opening <span class="text-white">hours</span></h6>
                <ul class="list-unstyled mb-0">
                  <li class="d-flex align-items-center justify-content-between pb-2">
                    <p class="text-sm mb-0">Monday </p><span class="text-sm mb-0">9:00 am - 06:00 pm</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between pb-2">
                    <p class="text-sm mb-0">Tuesday </p><span class="text-sm mb-0">9:00 am - 06:00 pm</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between pb-2">
                    <p class="text-sm mb-0">Wednesday </p><span class="text-sm mb-0">9:00 am - 06:00 pm</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between pb-2">
                    <p class="text-sm mb-0">Friday </p><span class="text-sm mb-0">9:00 am - 06:00 pm</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between pb-2">
                    <p class="text-sm mb-0">Saturday </p><span class="text-sm mb-0 text-uppercase text-primary">Closed</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between pb-2">
                    <p class="text-sm mb-0">Sunday </p><span class="text-sm mb-0 text-uppercase text-primary">Closed</span>
                  </li>
                </ul>
              </div>
              {/* Quick links */}
              {/* ... Other sections ... */}
            </div>
          </div>
          <div className="container text-center">
            <div className="border-top py-4">
              <p className="mb-0 text-white text-sm">&copy; 2024 RA Interiors Ltd. All rights reserved. </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
