import React from 'react';
import Header from '../components/header';
import PageHeader from '../components/page-header';
import Footer from '../components/footer';
import emailjs from 'emailjs-com';

const Contact = () => {
    // Function to handle form submission
    const sendEmail = (e) => {
        e.preventDefault(); // Prevents the default form submission behavior

        emailjs.sendForm('service_mija5sf', 'template_bmop7og', e.target, '5byznIDkZSKKzFaSU')
        .then((result) => {
            console.log(result.text);
            alert("Message sent successfully!");
        }, (error) => {
            console.log(error.text);
            alert("Failed to send the message, please try again.");
        });
    };

    return (
        <div>
            <Header activeLink="Contact"/>
            <PageHeader title="Contact" />
            <section className="py-5 bg-light shadow-sm z-index-20">
                <div className="container py-5">
                    <div className="card">
                    <div className="card-body p-4 p-lg-5">
                        <div className="row py-5">
                        <div className="col-lg-5">
                            <h2>Request a <span className="text-primary">Call </span>Back</h2>
                            <p className="mb-5 text-muted">For all your framing, drywalling, mudding, tapping, ceiling texture, and insulating needs, trust our experienced team to deliver quality craftsmanship and exceptional service. Contact us today to get started on your project!</p>
                            <ul className="list-unstyled mb-0">
                            <li>
                                <h5 className="text-muted">Address</h5>
                                <p className="text-muted">1798 Pretty View Lane, California</p>
                            </li>
                            <li>
                                <h5 className="text-muted">Email</h5>
                                <p className="text-muted">info@rainteriors.us</p>
                            </li>
                            <li>
                                <h5 className="text-muted">Phone</h5>
                                <p className="text-muted">+1 403 909 9463</p>
                            </li>
                            </ul>
                        </div>
                        <div className="col-lg-7">
                            <form onSubmit={sendEmail} method="POST">
                            <div className="row gy-3">
                                <div className="col-lg-6">
                                <input className="form-control" type="text" name="firstname" placeholder="First name" required />
                                </div>
                                <div className="col-lg-6">
                                <input className="form-control" type="text" name="lastname" placeholder="Last name" required />
                                </div>
                                <div className="col-lg-6">
                                <input className="form-control" type="tel" name="phone" placeholder="Phone number" required />
                                </div>
                                <div className="col-lg-6">
                                <input className="form-control" type="text" name="subject" placeholder="Subject" required />
                                </div>
                                <div className="col-lg-12">
                                <input className="form-control" type="email" name="email" placeholder="Email address" required />
                                </div>
                                <div className="col-lg-12">
                                <textarea className="form-control" name="message" rows="5" placeholder="Leave your message" required></textarea>
                                </div>
                                <div className="col-lg-12">
                                <button className="btn btn-outline-primary" type="submit">Submit now</button>
                                </div>
                            </div>
                            </form>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    );
};

export default Contact;
