import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import PageHeader from '../../components/page-header';
import Service from '../../components/service';;

const Services = () => {
    return (
        <div>
           <Header activeLink="Services"/>
           <PageHeader title="Services" />
           <Service/>
           <Footer/>
        </div>
    );
}

export default Services;
