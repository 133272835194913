import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import ServiceImage from '../../img/framing.jpg';

const Framing = () => {
    return (
        <div>
            <Header/>
            <section className='py-5 bg-light'>
                    <div class="container mt-5 mb-5">
                        <div class="row justify-content-between">

                            <div class="col-md-5">
                                <img className="w-100" src={ServiceImage} alt=""/>
                            </div>
                            
                            <div class="col-md-6">
                                <h2>Quality Crafted Indoor Framing</h2>
                                <p>At RA Interiors, our expertise in indoor framing sets the stage for transformative interior designs that resonate with elegance and structural integrity. Our dedicated professionals bring a wealth of experience to each project, ensuring that every frame we construct not only supports but enhances the overall design and functionality of your space.</p>
                                <p>We recognize the pivotal role that precise and robust framing plays in the overall success of interior projects. That's why we commit to using premium materials and advanced construction techniques to deliver framing solutions that are both durable and aesthetically pleasing. Our services cater to a wide range of needs, from delineating spaces within an open layout to constructing complex architectural features for both residential and commercial settings.</p>
                                <p>RA Interiors stands at the forefront of indoor framing, providing solutions that embody the perfect blend of form and function. Our attention to detail and passion for excellence make us the preferred choice for clients looking for framing that goes beyond mere structure to become an integral part of the interior's beauty. Let us partner with you to craft spaces that are not just built, but meticulously designed to last and inspire.</p>
                            </div>
                        </div>
                    </div>
            </section>
           <Footer/>
        </div>
    );
}

export default Framing;
