import React from 'react';
import ServiceImage from '../img/footer.jpg'; 

function PageHeader({ title }) {
  return (
    <section class="bg-cover bg-position" style={{ background: `url(${ServiceImage})` }}>
      <div class="dark-overlay dark-overlay-lighter py-5">
        <div class="overlay-content">
          <div class="container text-center py-5">
            <h1 class="text-white">{title}</h1>
            <nav class="d-flex justify-content-center" aria-label="breadcrumb">
              <ol class="breadcrumb bg-none mb-0">
                <li class="breadcrumb-item"><a href="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">{title}</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageHeader;
