import React, { useEffect } from 'react';
import TestimonialImage from '../img/testi.png';

function Testimonial() {
    useEffect(() => {
       new window.Swiper('.testimonials-slider', {
          slidesPerView: 1,
          spaceBetween: 30,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            dynamicBullets: true,
            clickable: true,
          },
          breakpoints: {
            991: {
              slidesPerView: 2,
            },
          },
        });
      }, []);
  return (
    <section class="py-5 bg-light">
      <div class="container py-5">
        <header class="text-center mb-5">
          <div class="row">
            <div class="col-lg-7 mx-auto">
              <h2 class="text-uppercase">What <span class="text-primary">our clients  </span>say</h2>
              <p class="text-muted">We are committed to constant improvement in all our operations, and to achieve this, we place a high value on feedback from our customers. Over the years, we have been gratified to receive numerous positive testimonials, which we take pride in sharing:  </p>
            </div>
          </div>
        </header>
        <div class="swiper testimonials-slider">
          <div class="swiper-wrapper">
            <div class="swiper-slide mb-5">
              <div class="card"> 
                <div class="card-body p-4 p-lg-5">
                  <div class="row">
                    <div class="col-lg-10">
                      <div class="d-flex">
                        <div class="flex-grow-1" style={{ minWidth: '60px' }}>
                                <img class="w-100" src={TestimonialImage} alt="..." />
                        </div>
                        <div class="ms-3 flex-grow-1">
                          <h3 class="h5">Transforming Spaces with Expertise</h3>
                          <ul class="list-inline small mb-4">
                            <li class="list-inline-item m-0"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item m-0 1"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item m-0 2"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item m-0 3"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item m-0 4"><i class="fas fa-star text-warning"></i></li>
                          </ul>
                          <p class="text-sm text-muted">I recently enlisted RA Interiors to add framing and drywall to my rental property, specifically focusing on the basement area. The team expertly handled every aspect of the project, from framing to drywall installation, taping, and mudding. Their attention to detail and commitment to quality were evident in every step. The basement transformation was remarkable, turning it into a functional and aesthetically pleasing space. I am thoroughly impressed with their professionalism and the seamless execution of the project. Thanks to RA Interiors, my rental property has significantly increased in value and appeal. I highly recommend their services for anyone looking to enhance their property with expert craftsmanship.</p>
                          <h6 class="mb-0">- Tarun Summan</h6>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2 d-none d-lg-block text-end"><i class="fas fa-quote-right fa-2x text-primary"></i></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide mb-5">
              <div class="card"> 
                <div class="card-body p-4 p-lg-5">
                  <div class="row">
                    <div class="col-lg-10">
                      <div class="d-flex">
                      <div class="flex-grow-1" style={{ minWidth: '60px' }}>
                                <img class="w-100" src={TestimonialImage} alt="..." />
                        </div>
                        <div class="ms-3 flex-grow-1">
                          <h3 class="h5">Professional handling</h3>
                          <ul class="list-inline small mb-4">
                            <li class="list-inline-item m-0"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item m-0 1"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item m-0 2"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item m-0 3"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item m-0 4"><i class="fas fa-star text-warning"></i></li>
                          </ul>
                          <p class="text-sm text-muted">After a thorough search for a reliable company to handle our home renovation, we decided on Ra Interiors, and it was the best decision we could have made. Their expertise in drywall installation and finishing transformed our living space into a sleek, modern home. The team was professional, punctual, and their attention to detail was unmatched. We were particularly impressed with their seamless mudding and taping work, which left our walls looking flawlessly smooth. Ra Interiors has our highest recommendation for anyone looking to upgrade their home.</p>
                          <h6 class="mb-0">- Marco Watkins</h6>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2 d-none d-lg-block text-end"><i class="fas fa-quote-right fa-2x text-primary"></i></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide mb-5">
              <div class="card"> 
                <div class="card-body p-4 p-lg-5">
                  <div class="row">
                    <div class="col-lg-10">
                      <div class="d-flex">
                      <div class="flex-grow-1" style={{ minWidth: '60px' }}>
                                <img class="w-100" src={TestimonialImage} alt="..." />
                        </div>
                        <div class="ms-3 flex-grow-1">
                          <h3 class="h5">Outstanding quality</h3>
                          <ul class="list-inline small mb-4">
                            <li class="list-inline-item m-0"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item m-0 1"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item m-0 2"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item m-0 3"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item m-0 4"><i class="fas fa-star text-warning"></i></li>
                          </ul>
                          <p class="text-sm text-muted">In my 15 years as a general contractor, working with RA Interiors has been among the best experiences. Their proficiency in ceiling installations and drywall finishing is unmatched. They are not only skilled craftsmen but also incredibly professional and reliable. The quality of their work has consistently exceeded my expectations and those of my clients. I highly recommend Innovative Home Solutions for any construction or renovation project requiring precision and excellence.</p>
                          <h6 class="mb-0">- Reynolds, General Contractor</h6>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2 d-none d-lg-block text-end"><i class="fas fa-quote-right fa-2x text-primary"></i></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide mb-5">
              <div class="card"> 
                <div class="card-body p-4 p-lg-5">
                  <div class="row">
                    <div class="col-lg-10">
                      <div class="d-flex">
                      <div class="flex-grow-1" style={{ minWidth: '60px' }}>
                                <img class="w-100" src={TestimonialImage} alt="..." />
                        </div>
                        <div class="ms-3 flex-grow-1">
                          <h3 class="h5">Elevating Business Environments</h3>
                          <ul class="list-inline small mb-4">
                            <li class="list-inline-item m-0"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item m-0 1"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item m-0 2"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item m-0 3"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item m-0 4"><i class="fas fa-star text-warning"></i></li>
                          </ul>
                          <p class="text-sm text-muted">We recently had the opportunity to collaborate with Ra Interiors on a significant renovation project for our commercial plaza. Right from the outset, their proficiency in drywall installation and insulation was unmistakably clear. The meticulousness of their mudding and taping work ensured that every wall surface was finished to perfection. What truly stood out to us was Ra Interiors' dedication to adhering to the project timeline and their constant communication throughout the process. Thanks to their exceptional insulation services, our plaza has become a more comfortable and energy-efficient environment for businesses and visitors alike. We are beyond satisfied with the results and the transformation of our commercial space.</p>
                          <h6 class="mb-0">- Lucas Grant</h6>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2 d-none d-lg-block text-end"><i class="fas fa-quote-right fa-2x text-primary"></i></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
