import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import ServiceImage from '../../img/mudding.jpg';

const Mudding = () => {
    return (
        <div>
            <Header/>
            <section className='py-5 bg-light'>
                    <div class="container mt-5 mb-5">
                        <div class="row justify-content-between">

                            <div class="col-md-5">
                                <img className="w-100" src={ServiceImage} alt=""/>
                            </div>
                            
                            <div class="col-md-6">
                                <h2>Precision Mudding: Flawless Surfaces</h2>
                                <p>Mudding, a critical phase in the drywall finishing process, involves the application of joint compound (or 'mud') to cover and smooth over seams, nails, and screw indentations in drywall panels. This meticulous step is essential for creating a seamless, uniform surface that's ready for painting or wallpapering. At RA Interiors, our mudding technique is performed with precision and care, ensuring that every wall and ceiling we work on is flawlessly smooth and free from imperfections.</p>
                                <p>Our skilled professionals are adept at all stages of the mudding process, from mixing the perfect consistency of joint compound to applying it with expert techniques that minimize sanding time and dust. We pay special attention to the feathering of edges, ensuring that the transition between the drywall panels and the applied mud is imperceptible, resulting in a perfectly smooth finish.</p>
                                <p>Mudding is not just about covering imperfections; it's an art that requires a keen eye for detail and a steady hand. Whether it's for a residential renovation or a commercial construction project, RA Interiors is committed to achieving the highest quality finish, providing a pristine canvas for your interior design aspirations.</p>
                            </div>
                        </div>
                    </div>
            </section>
           <Footer/>
        </div>
    );
}

export default Mudding;
