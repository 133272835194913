import React from 'react';
import { Link } from 'react-router-dom'

import LogoImage from '../img/logo2.jpg';

function Header({activeLink }) {
  const navItems = [
    { path: "/", label: "Home" },
    { path: "/pages/services", label: "Services" },
    { path: "/pages/contact", label: "Contact" },
    { path: "/pages/about", label: "About" }
  ];
  return (
    <header class="header">
      <div class="bg-primary text-white py-2">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 d-none d-lg-block">
              <ul class="list-inline mb-0">
                <li class="list-inline-item me-3"><a class="reset-anchor" href="#!"><i class="fas fa-mobile me-2"></i><span class="fw-normal text-sm">+1 403 903 9463</span></a></li>
                <li class="list-inline-item"><a class="reset-anchor" href="#!"><i class="fas fa-envelope me-2"></i><span class="fw-normal text-sm">raj@rainteriors.us</span></a></li>
              </ul>
            </div>
            <div class="col-lg-5 text-end">
              <ul class="list-inline mb-0">
                <li class="list-inline-item"><a class="fw-bold text-sm reset-anchor" href="#!">FAQ</a></li>
                <li class="list-inline-item">|</li>
                <li class="list-inline-item"><a class="fw-bold text-sm reset-anchor" href="#!">Contact</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light py-3 bg-white">
        <div class="container">
        <img src={LogoImage} alt="" class="responsive-logo"/>
          <button class="navbar-toggler navbar-toggler-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">
                    {navItems.map(item => (
                      <li key={item.path} className="nav-item mx-2">
                        <Link 
                          className={`nav-link ${activeLink === item.label ? 'active' : ''}`} 
                          to={item.path}
                        >
                          {item.label}
                        </Link>
                      </li>   
                      ))}
                      <li class="nav-item ms-2 dropdown">
                <div class="dropdown-menu dropdown-menu-end mt-lg-4" aria-labelledby="navbarDropdownMenuLink"><a class="dropdown-item text-sm" href="index.html">Home</a><a class="dropdown-item text-sm" href="services.html">Services</a><a class="dropdown-item text-sm" href="contact.html">Contact                </a></div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
